import React from 'react';
import {
    Box,
    Text,
    VStack,
    Flex,
    Heading,
    Grid,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { PurchaseOrderValidation } from "../../types/invoice.types";

interface PurchaseOrderSectionProps {
    validation: PurchaseOrderValidation;
}

export const PurchaseOrderSection: React.FC<PurchaseOrderSectionProps> = ({ validation }) => {
    const [isPOExpanded, setIsPOExpanded] = React.useState(false);
    console.log(validation);

    return (
        <Box
            bg="white"
            p={4}
            borderRadius="lg"
            boxShadow="sm"
            border="1px"
            borderColor="gray.200"
        >
            <VStack align="stretch" spacing={4}>
                <Flex 
                    justify="space-between" 
                    align="center" 
                    cursor="pointer"
                    onClick={() => setIsPOExpanded(!isPOExpanded)}
                    _hover={{ bg: 'gray.50' }}
                    p={2}
                    borderRadius="md"
                >
                    <Heading size="sm">Orden de Compra</Heading>
                    {isPOExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </Flex>
                
                <Box display={isPOExpanded ? "block" : "none"}>
                    {/* Purchase Order Details Grid */}
                    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                        <Box>
                            <Text fontWeight="bold">ID Orden</Text>
                            <Text>{validation.po_id}</Text>
                        </Box>
                        <Box>
                            <Text fontWeight="bold">Punto de Venta</Text>
                            <Text>{validation.pos}</Text>
                        </Box>
                        <Box>
                            <Text fontWeight="bold">Proveedor</Text>
                            <Text>{validation.provider_name}</Text>
                        </Box>
                        <Box>
                            <Text fontWeight="bold">Fecha de Creación</Text>
                            <Text>{new Date(validation.creation_date).toLocaleDateString()}</Text>
                        </Box>
                        {/* <Box>
                            <Text fontWeight="bold">Fecha de Vencimiento</Text>
                            <Text>{new Date(validation.creation_date).toLocaleDateString()}</Text>
                        </Box> */}
                        <Box>
                            <Text fontWeight="bold">NIT Proveedor</Text>
                            <Text>{validation.provider_nit}</Text>
                        </Box>
                    </Grid>

                    {/* Observations if they exist */}
                    {/* {validation.observations && (
                        <Box mt={4}>
                            <Text fontWeight="bold">Observaciones</Text>
                            <Text>{validation.observations}</Text>
                        </Box>
                    )} */}

                    {/* Purchase Order Items Table */}
                    <Box mt={4}>
                        <Text fontWeight="bold" mb={2}>Items de la Orden</Text>
                        <Box overflowX="auto">
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr style={{ backgroundColor: 'var(--chakra-colors-gray-50)' }}>
                                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid var(--chakra-colors-gray-200)' }}>Descripción</th>
                                        <th style={{ padding: '8px', textAlign: 'right', borderBottom: '1px solid var(--chakra-colors-gray-200)' }}>Cantidad</th>
                                        <th style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid var(--chakra-colors-gray-200)' }}>Unidad</th>
                                        <th style={{ padding: '8px', textAlign: 'right', borderBottom: '1px solid var(--chakra-colors-gray-200)' }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {validation.items.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ padding: '8px', borderBottom: '1px solid var(--chakra-colors-gray-100)' }}>{item.description}</td>
                                            <td style={{ padding: '8px', textAlign: 'right', borderBottom: '1px solid var(--chakra-colors-gray-100)' }}>{item.quantity}</td>
                                            <td style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid var(--chakra-colors-gray-100)' }}>{item.unit}</td>
                                            <td style={{ padding: '8px', textAlign: 'right', borderBottom: '1px solid var(--chakra-colors-gray-100)' }}>${item.total.toLocaleString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Box>
                    </Box>
                </Box>
            </VStack>
        </Box>
    );
}; 