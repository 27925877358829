import React, { useState, useMemo, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Collapse,
    Icon,
    Box,
    VStack,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    List,
    ListItem,
    Badge,
    Select,
    Flex,
} from "@chakra-ui/react";
import { Invoice } from "../../types/invoice.types";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { getConvertedQuantity } from './utils/conversionFactors';
import { fetchStoreIds } from "../../services/invoices.services";

interface UploadConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (storeSelections?: StoreSelection) => void;
    uploadTarget: string;
    selectedInvoices: string[];
    selectedInvoicesData: Invoice[];
}

type StoreSelection = {
    [invoiceId: string]: string;
};

const InvoiceRow: React.FC<{ 
    invoice: Invoice;
    hasErrors: boolean;
    storeIds: Record<string, string>;
    storeSelection?: string;
    onStoreSelect: (invoiceId: string, storeId: string) => void;
    showStoreSelect: boolean;
}> = ({ 
    invoice,
    hasErrors,
    storeIds,
    storeSelection,
    onStoreSelect,
    showStoreSelect,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
        }).format(amount);
    };

    const formatStoreName = (storeName: string) => {
        const storeNameParts = storeName.split('_');
        const formattedStoreName = storeNameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
        return formattedStoreName;
    };

    return (
        <>
            <Tr 
                cursor="pointer" 
                onClick={() => setIsOpen(!isOpen)}
                _hover={{ bg: "gray.50" }}
                bg={hasErrors ? "red.50" : undefined}
            >
                <Td width="40px">
                    <Icon 
                        as={isOpen ? FaChevronDown : FaChevronRight} 
                        color="gray.500"
                    />
                </Td>
                <Td>
                    {invoice.invoice_id}
                    {hasErrors && (
                        <Badge ml={2} colorScheme="red">
                            Requiere atención
                        </Badge>
                    )}
                </Td>
                <Td>{invoice.sender_party_name}</Td>
                <Td>{new Date(invoice.issue_date).toLocaleDateString()}</Td>
                {showStoreSelect && (
                    <Td onClick={e => e.stopPropagation()}>
                        <Select
                            value={storeSelection || ''}
                            onChange={(e) => onStoreSelect(invoice.id, e.target.value)}
                            placeholder="Seleccionar tienda"
                            isRequired
                            size="sm"
                        >
                            {Object.entries(storeIds).map(([id, name]) => (
                                <option key={id} value={id}>
                                    {formatStoreName(id)} - {name}
                                </option>
                            ))}
                        </Select>
                    </Td>
                )}
            </Tr>
            <Tr>
                <Td colSpan={4} p={0}>
                    <Collapse in={isOpen} animateOpacity>
                        <Box 
                            p={4} 
                            bg="gray.50" 
                            borderBottom="1px" 
                            borderColor="gray.200"
                        >
                            <Table size="sm" variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Producto</Th>
                                        <Th isNumeric>Cantidad</Th>
                                        <Th isNumeric>Precio unitario</Th>
                                        <Th isNumeric>Total</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {invoice.items.map((item, index) => (
                                        <Tr 
                                            key={index}
                                            bg={!item.inventory_item_name ? "red.50" : undefined}
                                        >
                                            <Td>
                                                {item.description}
                                                {!item.inventory_item_name && (
                                                    <Badge ml={2} colorScheme="red">
                                                        Falta nombre de inventario
                                                    </Badge>
                                                )}
                                            </Td>
                                            <Td isNumeric>
                                                {item.converted_quantity || (item.conversion && item.unit ? getConvertedQuantity(item.quantity, item.conversion, item.unit, false) : item.quantity)} {item.unit}
                                            </Td>
                                            <Td isNumeric>
                                                {formatCurrency(item.converted_price || (item.conversion && item.unit ? getConvertedQuantity(item.price, item.conversion, item.unit, true) : item.price_per_unit))}
                                            </Td>
                                            <Td isNumeric>
                                                {formatCurrency((item.converted_quantity || (item.conversion && item.unit ? getConvertedQuantity(item.quantity, item.conversion, item.unit, false) : item.quantity)) * (item.converted_price || (item.conversion && item.unit ? getConvertedQuantity(item.price, item.conversion, item.unit, true) : item.price_per_unit)))}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                    </Collapse>
                </Td>
            </Tr>
        </>
    );
};

export const UploadConfirmationModal: React.FC<UploadConfirmationModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    uploadTarget,
    selectedInvoicesData,
    selectedInvoices,
}) => {
    const [storeIds, setStoreIds] = useState<Record<string, string>>({});
    const [storeSelections, setStoreSelections] = useState<StoreSelection>({});
    const [isLoadingStores, setIsLoadingStores] = useState(false);


    useEffect(() => {
        if (isOpen && uploadTarget === 'intuipos') {
            loadStoreIds();
        }
    }, [isOpen, uploadTarget]);

    const loadStoreIds = async () => {
        setIsLoadingStores(true);
        try {
            const response = await fetchStoreIds();
            setStoreIds(response.store_ids);
            
            // Initialize store selections
            const initialSelections: StoreSelection = {};
            
            // If there's only one store, automatically select it for all invoices
            if (Object.values(response.store_ids).length === 1) {
                const onlyStoreId = Object.values(response.store_ids)[0];
                selectedInvoicesData.forEach(invoice => {
                    initialSelections[invoice.id] = onlyStoreId;
                });
            } else {
                // If multiple stores, only initialize from existing store_id
                selectedInvoicesData.forEach(invoice => {
                    if (invoice.store_id && response.store_ids[invoice.store_id]) {
                        initialSelections[invoice.id] = invoice.store_id;
                    }
                });
            }
            setStoreSelections(initialSelections);
        } catch (error) {
            console.error('Failed to load store IDs:', error);
        } finally {
            setIsLoadingStores(false);
        }
    };

    const handleStoreSelection = (invoiceId: string, storeId: string) => {
        setStoreSelections(prev => ({
            ...prev,
            [invoiceId]: storeId
        }));
    };

    const invoicesWithErrors = useMemo(() => {
        return selectedInvoicesData.reduce((acc, invoice) => {
            const itemsWithoutName = invoice.items
                .map((item, index) => ({ ...item, index }))
                .filter(item => !item.inventory_item_name);
            
            if (itemsWithoutName.length > 0) {
                acc.push({
                    invoiceId: invoice.invoice_id,
                    items: itemsWithoutName
                });
            }
            return acc;
        }, [] as Array<{ invoiceId: string; items: Array<any> }>);
    }, [selectedInvoices]);

    const hasErrors = invoicesWithErrors.length > 0;

    const canConfirm = useMemo(() => {
        // If there are validation errors, can't confirm
        if (hasErrors) return false;
        
        // If not uploading to Intuipos, can confirm
        if (uploadTarget !== 'intuipos') return true;
        
        // If there's only one store, can confirm (as all will be auto-selected)
        if (Object.keys(storeIds).length === 1) return true;
        
        // If multiple stores, ensure all invoices have a store selected
        return selectedInvoicesData.every(invoice => storeSelections[invoice.id]);
    }, [uploadTarget, storeIds, selectedInvoicesData, storeSelections, hasErrors]);

    const showStoreSelect = uploadTarget === 'intuipos' && Object.keys(storeIds).length > 1;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent maxWidth="80vw">
                <ModalHeader>
                    Confirmar carga a {uploadTarget?.charAt(0).toUpperCase() + uploadTarget?.slice(1)}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {hasErrors && (
                        <Alert 
                            status="error" 
                            mb={4}
                            flexDirection="column"
                            alignItems="flex-start"
                        >
                            <AlertIcon />
                            <AlertTitle mb={2}>
                                Se encontraron problemas en algunas facturas
                            </AlertTitle>
                            <AlertDescription width="100%">
                                <Text mb={2}>
                                    Las siguientes facturas tienen productos sin nombre de inventario asignado:
                                </Text>
                                <List spacing={2}>
                                    {invoicesWithErrors.map(({ invoiceId, items }) => (
                                        <ListItem key={invoiceId}>
                                            <Text fontWeight="bold">
                                                Factura {invoiceId}:
                                            </Text>
                                            <List pl={4} styleType="disc">
                                                {items.map((item, idx) => (
                                                    <ListItem key={idx}>
                                                        Producto: "{item.description}" (ítem {item.index + 1})
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </ListItem>
                                    ))}
                                </List>
                                <Text mt={2} fontWeight="medium">
                                    Por favor, asigne los nombres de inventario antes de continuar.
                                </Text>
                            </AlertDescription>
                        </Alert>
                    )}
                    <Text mb={4}>Se cargarán las siguientes facturas:</Text>
                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr>
                                <Th width="40px"></Th>
                                <Th>ID Factura</Th>
                                <Th>Proveedor</Th>
                                <Th>Fecha</Th>
                                {showStoreSelect && <Th>Tienda</Th>}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {selectedInvoicesData.map((invoice) => (
                                <InvoiceRow 
                                    key={invoice.id} 
                                    invoice={invoice}
                                    hasErrors={invoicesWithErrors.some(e => e.invoiceId === invoice.invoice_id)}
                                    storeIds={storeIds}
                                    storeSelection={storeSelections[invoice.id]}
                                    onStoreSelect={handleStoreSelection}
                                    showStoreSelect={showStoreSelect}
                                />
                            ))}
                        </Tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Flex direction="column" align="flex-end">
                        {uploadTarget === 'intuipos' && Object.keys(storeIds).length > 1 && !canConfirm && (
                            <Text fontSize="sm" color="red.500" mb={2}>
                                Seleccione una tienda para todas las facturas
                            </Text>
                        )}
                        <Button 
                            colorScheme="blue" 
                            onClick={() => onConfirm(storeSelections)}
                            isDisabled={!canConfirm}
                            isLoading={isLoadingStores}
                        >
                            Confirmar
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}; 