import {auth} from "../config/firebaseConfig";

export const getToken = async () => {
    const user = auth.currentUser;

    if (!user) {
        throw new Error('User is not authenticated');
    }

    return await user.getIdToken();
};