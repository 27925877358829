import React from 'react';
import {
    Button,
    HStack,
    Image,
    Text,
    Flex,
    useToast,
} from "@chakra-ui/react";
import { Invoice } from "../../types/invoice.types";
import {
    uploadInvoicesHelisa,
    uploadInvoicesIntuipos,
    uploadInvoicesSiigo,
    updateInvoice,
    getDianAcknowledgment,
    updateInvoiceStoreId,
    fetchStoreIds,
} from "../../services/invoices.services";
import { UploadConfirmationModal } from "./UploadConfirmationModal";
import { DianAcknowledgmentModal } from "./DianAcknowledgmentModal";

interface UploadControlsProps {
    selectedInvoices: string[];
    selectedInvoicesData: Invoice[];
    isDetailView?: boolean;
    currentInvoice?: Invoice | null;
    onUploadComplete?: () => void;
}

export const UploadControls: React.FC<UploadControlsProps> = ({ 
    selectedInvoices,
    selectedInvoicesData,
    isDetailView = false,
    currentInvoice = null,
    onUploadComplete,
}) => {
    const [isUploadModalOpen, setIsUploadModalOpen] = React.useState(false);
    const [uploadTarget, setUploadTarget] = React.useState<'intuipos' | 'siigo' | 'helisa' | 'dian' | ''>('');
    const toast = useToast();
    const [isDianModalOpen, setIsDianModalOpen] = React.useState(false);

    const handleUploadClick = (target: 'intuipos' | 'siigo' | 'helisa') => {
        if (selectedInvoices.length === 0) {
            toast({
                title: "No hay facturas seleccionadas",
                description: "Por favor, seleccione al menos una factura para subir",
                status: "warning",
                duration: 3000,
                isClosable: true,
                position: "top-right"
            });
            return;
        }
        setUploadTarget(target);
        setIsUploadModalOpen(true);
    };

    const handleConfirmUpload = async (storeSelections?: Record<string, string>) => {
        if (!uploadTarget) return;

        try {
            // If we're in detail view and have a current invoice, update it first
            if (isDetailView && currentInvoice) {
                const updateResult = await updateInvoice({ invoice: currentInvoice });
                if (updateResult.status !== 'success') {
                    toast({
                        title: "Error al actualizar la factura",
                        description: "Por favor, intente nuevamente",
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsUploadModalOpen(false);
                    return;
                }
            }

            // If uploading to Intuipos and we have store selections
            if (uploadTarget === 'intuipos' && storeSelections) {
                // Get store names from the modal's store IDs
                const storeNames = await fetchStoreIds();
                
                // Update store IDs for each invoice that needs it
                for (const invoice of selectedInvoicesData) {
                    const storeId = storeSelections[invoice.id];
                    if (storeId) {
                        const storeName = Object.keys(storeNames.store_ids).find(key => storeNames.store_ids[key] === storeId) || '';
                        const updateResult = await updateInvoiceStoreId(
                            invoice.id,
                            storeId,
                            storeName
                        );
                        
                        if (updateResult.status !== 'success') {
                            toast({
                                title: "Error al actualizar tienda",
                                description: `No se pudo actualizar la tienda para la factura ${invoice.invoice_id}`,
                                status: 'error',
                                duration: 3000,
                                isClosable: true,
                            });
                            setIsUploadModalOpen(false);
                            return;
                        }
                    }
                }
            }

            // Prepare invoices for upload
            let invoicesToUpload: { id: string; store_id: string }[] = [];
            if (uploadTarget === 'intuipos' && storeSelections) {
                invoicesToUpload = selectedInvoicesData.map(invoice => ({
                    id: invoice.id,
                    store_id: storeSelections[invoice.id]
                }));
            }

            let data;
            switch (uploadTarget) {
                case 'intuipos':
                    data = await uploadInvoicesIntuipos(invoicesToUpload);
                    break;
                case 'siigo':
                    data = await uploadInvoicesSiigo(selectedInvoices);
                    break;
                case 'helisa':
                    data = await uploadInvoicesHelisa(selectedInvoices);
                    break;
            }

            if (uploadTarget === 'helisa') {
                if (data.url !== null) {
                    toast({
                        position: "bottom",
                        title: "El archivo plano se ha generado correctamente",
                        status: 'success',
                        duration: 12000,
                        isClosable: true,
                    });
                    window.open(data.url, '_blank');
                } else {
                    toast({
                        position: "top-right",
                        title: "El archivo plano no se ha generado correctamente",
                        status: 'error',
                        duration: 12000,
                        isClosable: true,
                    });
                }
            } else {
                const title = uploadTarget === 'intuipos' 
                    ? "Facturas subidas a Intuipos"
                    : "Facturas subidas a Siigo";

                const description = data.status === "success"
                    ? `Facturas subidas correctamente ${data.successfully_uploaded.length} \n Facturas con error ${data.error_uploaded.length}`
                    : "No se han seleccionado facturas para subir";

                toast({
                    position: "top-right",
                    title,
                    description,
                    status: data.status,
                    duration: 12000,
                    isClosable: true,
                });
            }

            onUploadComplete?.();
            
        } catch (error) {
            console.error('Error during upload:', error);
            toast({
                title: "Error en el proceso",
                description: "Hubo un error al procesar la factura",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setIsUploadModalOpen(false);
    };

    const handleDianClick = () => {
        if (selectedInvoices.length === 0) {
            toast({
                title: "No hay facturas seleccionadas",
                description: "Por favor, seleccione al menos una factura para generar el acuse de recibo",
                status: "warning",
                duration: 3000,
                isClosable: true,
                position: "top-right"
            });
            return;
        }
        setIsDianModalOpen(true);
    };

    const handleConfirmDianAcknowledgment = async () => {
        try {
            const data = await getDianAcknowledgment(selectedInvoices);
            
            toast({
                position: "top-right",
                title: "Acuse de recibo DIAN",
                description: data.message,
                status: data.status,
                duration: 5000,
                isClosable: true,
            });

            onUploadComplete?.();
            
        } catch (error) {
            toast({
                title: "Error al obtener acuse",
                description: "Hubo un error al procesar la solicitud",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setIsDianModalOpen(false);
    };

    return (
        <>
            <HStack spacing={3}>
                <Button
                    size="sm"
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => handleUploadClick('intuipos')}
                >
                    <Flex align="center" gap={2}>
                        <Image src="/intuipos_icon.png" alt="Intuipos" boxSize="16px"/>
                        <Text>Subir a Intuipos</Text>
                    </Flex>
                </Button>

                <Button
                    size="sm"
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => handleUploadClick('siigo')}
                >
                    <Flex align="center" gap={2}>
                        <Image src="/siigo_blue_icon.png" alt="Siigo" boxSize="16px"/>
                        <Text>Subir a Siigo</Text>
                    </Flex>
                </Button>

                <Button
                    size="sm"
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => handleUploadClick('helisa')}
                >
                    <Flex align="center" gap={2}>
                        <Image src="/helisa_logo.png" alt="Helisa" width="16px"/>
                        <Text>Subir a Helisa</Text>
                    </Flex>
                </Button>

                <Button
                    size="sm"
                    colorScheme="blue"
                    variant="outline"
                    onClick={handleDianClick}
                >
                    <Flex align="center" gap={2}>
                        <Image src="/dian_icon.png" alt="DIAN" boxSize="16px"/>
                        <Text>Acuse de recibo</Text>
                    </Flex>
                </Button>
            </HStack>

            <UploadConfirmationModal
                isOpen={isUploadModalOpen}
                onClose={() => setIsUploadModalOpen(false)}
                onConfirm={handleConfirmUpload}
                uploadTarget={uploadTarget}
                selectedInvoices={selectedInvoices}
                selectedInvoicesData={selectedInvoicesData}
            />

            <DianAcknowledgmentModal
                isOpen={isDianModalOpen}
                onClose={() => setIsDianModalOpen(false)}
                onConfirm={handleConfirmDianAcknowledgment}
                selectedInvoices={selectedInvoicesData}
            />
        </>
    );
}; 