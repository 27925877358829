import {Divider, Grid, GridItem} from "@chakra-ui/react"
import {Outlet} from "react-router-dom"
import Sidebar from "../components/layout/SideBar";
import Navbar from "../components/layout/NavBar";

export default function RootLayout() {
    return (
        <Grid templateColumns="repeat(6, 1fr)" bg="gray.50" minHeight={{lg: '100vh'}}
              overflow={"hidden"}>
            <GridItem
                as="aside"
                colSpan={{base: 6, lg: 2, xl: 1}}
                bg={"blue.400"}
                minHeight={{lg: '100%'}}
                height={{lg: "100%"}}
                p={{base: '35px', lg: '35px'}}
                borderTopRightRadius={"10px"}
                borderBottomRightRadius={"10px"}
            >
                <Sidebar/>
            </GridItem>

            {/* main content & navbar */}
            <GridItem
                as="main"
                colSpan={{base: 6, lg: 4, xl: 5}}
                p="40px"
                minHeight={{lg: '100%'}}
                height={{lg: "100%"}}
            >
                <Navbar/>
                <Divider bg={'grey'} mb={"30px"}/>
                <Outlet/>
            </GridItem>
        </Grid>
    )
}
