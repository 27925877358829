import React, { useEffect, useState } from "react";
import {
    Box,
    Flex,
    FormControl,
    Heading,
    Input,
    Text,
    useToast,
    Select,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Icon,
    IconButton,
    Tooltip,
    Image,
    Checkbox,
    Skeleton,
    InputGroup,
    InputLeftElement,
} from "@chakra-ui/react";
import { FaChevronLeft, FaChevronRight, FaExclamationTriangle, FaCheck, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Invoice, InvoiceValidations, PaginatedInvoicesResponse, TaxxaValidation } from "../types/invoice.types";
import {
    fetchInvoices,
    uploadInvoicesHelisa,
    uploadInvoicesIntuipos,
    uploadInvoicesSiigo,
} from "../services/invoices.services";
import { UploadConfirmationModal } from "../components/invoicing/UploadConfirmationModal";
import { UploadControls } from "../components/invoicing/UploadControls";
import { useDebounce } from '../hooks/useDebounce';
import { CgFileDocument } from "react-icons/cg";

type RowProps = {
    children: React.ReactNode;
    onClick: () => void;
};

const ClickableRow: React.FC<RowProps> = ({ children, onClick }) => (
    <Tr
        cursor="pointer"
        _hover={{ bg: 'gray.50' }}
        onClick={onClick}
        transition="background-color 0.2s"
    >
        {children}
    </Tr>
);

export default function Invoices() {
    const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
    const [filteredInvoices, setFilteredInvoices] = useState<Invoice[]>([]);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalInvoices, setTotalInvoices] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [hasMore, setHasMore] = useState(false);
    const [uploadStatus, setUploadStatus] = useState<string>('all');
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [uploadTarget, setUploadTarget] = useState<'intuipos' | 'siigo' | 'helisa' | ''>('');
    const [selectedInvoicesData, setSelectedInvoicesData] = useState<Invoice[]>([]);
    const [providerInputValue, setProviderInputValue] = useState<string>('');
    const debouncedProviderFilter = useDebounce(providerInputValue, 500);

    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        loadInvoices();
    }, [currentPage, pageSize, startDate, endDate, uploadStatus, debouncedProviderFilter]);

    const loadInvoices = async () => {
        setIsLoading(true);
        try {
            const data = await fetchInvoices(
                currentPage, 
                pageSize, 
                startDate || undefined, 
                endDate || undefined,
                uploadStatus,
                debouncedProviderFilter || undefined
            );
            setFilteredInvoices(data.invoices);
            setTotalInvoices(data.total);
            setHasMore(data.has_more);
        } catch (error) {
            console.error("Error fetching invoices:", error);
            toast({
                title: "Error fetching invoices",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date = e.target.value || null;
        setStartDate(date);
        setCurrentPage(1); // Reset to first page when filtering
    };

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date = e.target.value || null;
        setEndDate(date);
        setCurrentPage(1); // Reset to first page when filtering
    };

    const handleSelectInvoice = (invoice: Invoice) => {
        let newSelectedInvoices: string[];
        let newSelectedInvoicesData: Invoice[];

        if (selectedInvoices.includes(invoice.id)) {
            // Remove from selection
            newSelectedInvoices = selectedInvoices.filter(id => id !== invoice.id);
            newSelectedInvoicesData = selectedInvoicesData.filter(inv => inv.id !== invoice.id);
        } else {
            // Add to selection
            newSelectedInvoices = [...selectedInvoices, invoice.id];
            newSelectedInvoicesData = [...selectedInvoicesData, invoice];
        }

        setSelectedInvoices(newSelectedInvoices);
        setSelectedInvoicesData(newSelectedInvoicesData);
    };

    const handleCopyToClipboard = (text: string, description: string) => {
        navigator.clipboard.writeText(text).then(() => {
            toast({
                title: `${description} copiado al portapapeles`,
                status: 'info',
                duration: 3500,
                isClosable: true,
            });
        });
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
        // Reset selected invoices when changing pages
        setSelectedInvoices([]);
    };

    const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newPageSize = parseInt(e.target.value);
        setPageSize(newPageSize);
        setCurrentPage(1); // Reset to first page when changing page size
        // Reset selected invoices when changing page size
        setSelectedInvoices([]);
    };

    const handleUploadStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newUploadStatus = e.target.value;
        setUploadStatus(newUploadStatus);
        setCurrentPage(1); // Reset to first page when filtering
    };

    const handleConfirmUpload = async (storeSelections?: Record<string, string>) => {
        console.log("handleConfirmUpload")
        if (!uploadTarget) return;

        const uploadFunctions = {
            intuipos: uploadInvoicesIntuipos,
            siigo: uploadInvoicesSiigo,
            helisa: uploadInvoicesHelisa
        };

        const uploadFunction = uploadFunctions[uploadTarget];
        
        // If uploading to Intuipos and we have store selections, prepare the data accordingly
        let invoicesToUpload: { id: string; store_id: string }[] = [];
        if (uploadTarget === 'intuipos' && storeSelections) {
            invoicesToUpload = selectedInvoicesData.map(invoice => ({
                id: invoice.id,
                store_id: storeSelections[invoice.id]
            }));
        }
        
        uploadFunction(invoicesToUpload).then((data) => {
            if (uploadTarget === 'helisa') {
                if (data.url !== null) {
                    toast({
                        position: "bottom",
                        title: "El archivo plano se ha generado correctamente",
                        status: 'success',
                        duration: 12000,
                        isClosable: true,
                    });
                    window.open(data.url, '_blank');
                } else {
                    toast({
                        position: "top-right",
                        title: "El archivo plano no se ha generado correctamente",
                        status: 'error',
                        duration: 12000,
                        isClosable: true,
                    });
                }
            } else {
                const title = uploadTarget === 'intuipos' 
                    ? "Facturas subidas a Intuipos"
                    : "Facturas subidas a Siigo";

                const description = data.status === "success"
                    ? `Facturas subidas correctamente ${data.successfully_uploaded.length} \n Facturas con error ${data.error_uploaded.length}`
                    : "No se han seleccionado facturas para subir";

                toast({
                    position: "top-right",
                    title,
                    description,
                    status: data.status,
                    duration: 12000,
                    isClosable: true,
                });
            }
        });
        setIsUploadModalOpen(false);
    };

    const handleUploadComplete = () => {
        loadInvoices();  // Refresh the invoices
        setSelectedInvoices([]); // Clear selection after upload
        setSelectedInvoicesData([]); // Clear selected data
    };

    const handleProviderFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProviderInputValue(e.target.value);
        setCurrentPage(1); // Reset to first page when filtering
    };

    const getTaxxaStatusLabel = (validations?: InvoiceValidations): string => {
        if (!validations?.taxxa) return 'Pendiente de acuse de recibo';
        
        const taxxa = validations.taxxa;
        const processes = {
            'acuse_recibo': 'Acuse de recibo',
            'recibo_de_bienes': 'Recibo de bienes',
            'aceptacion_expresa': 'Aceptación expresa'
        };
        
        const completed = Object.entries(processes)
            .filter(([key]) => taxxa[key as keyof TaxxaValidation])
            .map(([_, label]) => label);
        
        const pending = Object.entries(processes)
            .filter(([key]) => !taxxa[key as keyof TaxxaValidation])
            .map(([_, label]) => label);
        
        if (completed.length === 0) return 'Pendiente de acuse de recibo';
        if (pending.length === 0) return 'Acuse de recibo completado';
        
        return `Completado: ${completed.join(', ')}\nPendiente: ${pending.join(', ')}`;
    };

    return (
        <Box height="100%" p={4}>
            <Flex direction="column" gap={6}>
                {/* Header Section */}
                <Flex 
                    justify="space-between" 
                    align="center"
                    bg="white"
                    p={4}
                    borderRadius="lg"
                    boxShadow="sm"
                    border="1px"
                    borderColor="gray.200"
                >
                    <Heading size="md" fontWeight="500" color="gray.700">
                        Facturas
                    </Heading>

                    <UploadControls 
                        selectedInvoices={selectedInvoices}
                        selectedInvoicesData={selectedInvoicesData}
                        isDetailView={false}
                        onUploadComplete={handleUploadComplete}
                    />
                </Flex>

                {/* Filters Section */}
                <Box 
                    bg="white" 
                    p={4} 
                    borderRadius="lg"
                    boxShadow="sm"
                    border="1px"
                    borderColor="gray.200"
                >
                    <Flex gap={4} wrap="wrap">
                        <FormControl flex="1" minW="200px">
                            <Text fontSize="sm" mb={2} color="gray.600">Fecha inicial</Text>
                            <Input
                                type="date"
                                bg="white"
                                color="gray.800"
                                size="md"
                                borderColor="gray.300"
                                _hover={{ borderColor: "gray.400" }}
                                _focus={{ borderColor: "blue.400", boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)" }}
                                value={startDate || ''}
                                onChange={handleStartDateChange}
                            />
                        </FormControl>
                        <FormControl flex="1" minW="200px">
                            <Text fontSize="sm" mb={2} color="gray.600">Fecha final</Text>
                            <Input
                                type="date"
                                bg="white"
                                color="gray.800"
                                size="md"
                                borderColor="gray.300"
                                _hover={{ borderColor: "gray.400" }}
                                _focus={{ borderColor: "blue.400", boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)" }}
                                value={endDate || ''}
                                onChange={handleEndDateChange}
                            />
                        </FormControl>
                        <FormControl flex="1" minW="200px">
                            <Text fontSize="sm" mb={2} color="gray.600">Estado de carga</Text>
                            <Select 
                                bg="white"
                                color="gray.800"
                                size="md"
                                borderColor="gray.300"
                                _hover={{ borderColor: "gray.400" }}
                                _focus={{ borderColor: "blue.400", boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)" }}
                                onChange={handleUploadStatusChange}
                                value={uploadStatus}
                            >
                                <option value="all">Todas</option>
                                <option value="uploaded">Cargadas</option>
                                {/* <option value="not-uploaded">No cargadas</option> */}
                            </Select>
                        </FormControl>
                        <FormControl flex="1" minW="200px">
                            <Text fontSize="sm" mb={2} color="gray.600">Buscar proveedor</Text>
                            <InputGroup>
                                <InputLeftElement pointerEvents='none'>
                                    <FaSearch />
                                </InputLeftElement>
                                <Input
                                    placeholder="Nombre del proveedor"
                                    bg="white"
                                    color="gray.800"
                                    size="md"
                                    borderColor="gray.300"
                                    _hover={{ borderColor: "gray.400" }}
                                    _focus={{ borderColor: "blue.400", boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)" }}
                                    value={providerInputValue}
                                    onChange={handleProviderFilterChange}
                                />
                            </InputGroup>
                        </FormControl>
                    </Flex>
                </Box>

                {/* Table Section */}
                <Box 
                    bg="white" 
                    borderRadius="lg"
                    boxShadow="sm"
                    border="1px"
                    borderColor="gray.200"
                    overflowX="auto"
                >
                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr>
                                <Th width="40px" px={2}>
                                    <Checkbox
                                        size="sm"
                                        isDisabled={isLoading}
                                        isChecked={selectedInvoices.length > 0 && selectedInvoices.length === filteredInvoices.length}
                                        isIndeterminate={selectedInvoices.length > 0 && selectedInvoices.length < filteredInvoices.length}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedInvoices(filteredInvoices.map(inv => inv.id));
                                                setSelectedInvoicesData(filteredInvoices);
                                            } else {
                                                setSelectedInvoices([]);
                                                setSelectedInvoicesData([]);
                                            }
                                        }}
                                    />
                                </Th>
                                <Th>ID Factura</Th>
                                <Th>Fecha</Th>
                                <Th>Proveedor</Th>
                                <Th>Validaciones</Th>
                                <Th>Estados de carga</Th>
                                <Th>Estado</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {isLoading ? (
                                // Loading skeleton rows
                                [...Array(5)].map((_, index) => (
                                    <Tr key={`skeleton-${index}`}>
                                        <Td px={2}><Skeleton height="20px" width="20px" /></Td>
                                        <Td><Skeleton height="20px" width="100px" /></Td>
                                        <Td><Skeleton height="20px" width="80px" /></Td>
                                        <Td><Skeleton height="20px" width="150px" /></Td>
                                        <Td>
                                            <HStack spacing={2}>
                                                <Skeleton height="16px" width="16px" />
                                                <Skeleton height="16px" width="16px" />
                                            </HStack>
                                        </Td>
                                        <Td>
                                            <HStack spacing={2}>
                                                <Skeleton height="16px" width="16px" />
                                                <Skeleton height="16px" width="16px" />
                                                <Skeleton height="16px" width="16px" />
                                            </HStack>
                                        </Td>
                                        <Td>
                                            <Skeleton height="20px" width="50px" />
                                        </Td>
                                    </Tr>
                                ))
                            ) : filteredInvoices.length === 0 ? (
                                <Tr>
                                    <Td colSpan={7}>
                                        <Flex 
                                            direction="column" 
                                            align="center" 
                                            justify="center" 
                                            py={10} 
                                            px={4}
                                            color="gray.500"
                                        >
                                            <Icon 
                                                as={CgFileDocument} 
                                                boxSize={12} 
                                                mb={4}
                                            />
                                            <Text fontSize="lg" fontWeight="medium" mb={2}>
                                                No se encontraron facturas
                                            </Text>
                                            <Text textAlign="center" fontSize="sm">
                                                {debouncedProviderFilter 
                                                    ? "Intenta ajustar tu búsqueda o usar otros filtros"
                                                    : "No hay facturas disponibles para los filtros seleccionados"}
                                            </Text>
                                        </Flex>
                                    </Td>
                                </Tr>
                            ) : (
                                // Existing table rows code
                                filteredInvoices.map((invoice) => {
                                    const hasAllInventoryNames = invoice.items.every(item => item.inventory_item_name);
                                    const missingItems = invoice.items.filter(item => !item.inventory_item_name).length;

                                    return (
                                        <ClickableRow
                                            key={invoice.id}
                                            onClick={() => {
                                                localStorage.setItem('selectedInvoice', JSON.stringify(invoice));
                                                navigate(`/invoice/${invoice.id}`);
                                            }}
                                        >
                                            <Td px={2} onClick={(e) => e.stopPropagation()}>
                                                <Checkbox
                                                    size="sm"
                                                    isChecked={selectedInvoices.includes(invoice.id)}
                                                    onChange={() => handleSelectInvoice(invoice)}
                                                />
                                            </Td>
                                            <Td>{invoice.invoice_id}</Td>
                                            <Td>{new Date(invoice.issue_date).toLocaleDateString()}</Td>
                                            <Td>{invoice.sender_party_name}</Td>
                                            <Td>
                                                <HStack spacing={2}>
                                                    <Tooltip label={invoice.validations?.purchase_order ? "Orden de compra encontrada" : "No se encontró orden de compra"}>
                                                        <Box opacity={invoice.validations?.purchase_order ? "100%" : "30%"}>
                                                            <Image 
                                                                src="/intuipos_icon.png" 
                                                                alt="Intuipos" 
                                                                boxSize="16px"
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                    <Tooltip 
                                                        label={getTaxxaStatusLabel(invoice.validations)}
                                                        placement="top"
                                                        hasArrow
                                                        whiteSpace="pre-wrap"
                                                    >
                                                        <Box opacity={
                                                            invoice.validations?.taxxa ? 
                                                                Object.values(invoice.validations.taxxa).some(v => v) ? "100%" : "30%" 
                                                            : "30%"
                                                        }>
                                                            <Image 
                                                                src="/dian_icon.png" 
                                                                alt="DIAN" 
                                                                boxSize="16px"
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                </HStack>
                                            </Td>
                                            <Td onClick={(e) => e.stopPropagation()}>
                                                <HStack spacing={2}>
                                                    {invoice.uploads?.intuipos?.uploaded && (
                                                        <Tooltip label={`OrderId: ${invoice.uploads.intuipos.order_id}`}>
                                                            <Box cursor="pointer" onClick={() => handleCopyToClipboard(
                                                                invoice.uploads?.intuipos?.order_id || '',
                                                                'OrderId'
                                                            )}>
                                                                <Image 
                                                                    src="/intuipos_icon.png" 
                                                                    alt="Intuipos" 
                                                                    boxSize="16px"
                                                                />
                                                            </Box>
                                                        </Tooltip>
                                                    )}
                                                    {invoice.uploads?.siigo?.uploaded && (
                                                        <Tooltip label={`Consecutivo: ${invoice.uploads.siigo.consecutive}`}>
                                                            <Box cursor="pointer" onClick={() => handleCopyToClipboard(
                                                                invoice.uploads?.siigo?.consecutive || '',
                                                                'Consecutivo'
                                                            )}>
                                                                <Image 
                                                                    src="/siigo_blue_icon.png" 
                                                                    alt="Siigo" 
                                                                    boxSize="16px"
                                                                />
                                                            </Box>
                                                        </Tooltip>
                                                    )}
                                                    {invoice.uploads?.helisa?.uploaded && (
                                                        <Tooltip label="Click para copiar URL">
                                                            <Box cursor="pointer" onClick={() => handleCopyToClipboard(
                                                                invoice.uploads?.helisa?.url || '',
                                                                'URL'
                                                            )}>
                                                                <Image 
                                                                    src="/helisa_logo.png" 
                                                                    alt="Helisa" 
                                                                    boxSize="16px"
                                                                />
                                                            </Box>
                                                        </Tooltip>
                                                    )}
                                                </HStack>
                                            </Td>
                                            <Td>
                                                <Tooltip label={
                                                    hasAllInventoryNames 
                                                        ? "Todos los productos tienen nombre de inventario asignado"
                                                        : `Faltan ${missingItems} producto${missingItems > 1 ? 's' : ''} por asignar nombre de inventario`
                                                }>
                                                    <Flex align="center" gap={2}>
                                                        {hasAllInventoryNames ? (
                                                            <Icon 
                                                                as={FaCheck} 
                                                                color="green.500"
                                                            />
                                                        ) : (
                                                            <Icon 
                                                                as={FaExclamationTriangle} 
                                                                color="orange.500"
                                                            />
                                                        )}
                                                        {!hasAllInventoryNames && (
                                                            <Text fontSize="sm" color="orange.500">
                                                                {missingItems}
                                                            </Text>
                                                        )}
                                                    </Flex>
                                                </Tooltip>
                                            </Td>
                                        </ClickableRow>
                                    );
                                })
                            )}
                        </Tbody>
                    </Table>
                </Box>

                {/* Pagination Section */}
                <Flex justify="space-between" align="center" mt={4}>
                    <HStack>
                        <Text>Facturas por página:</Text>
                        <Select value={pageSize} onChange={handlePageSizeChange} w="70px">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                        </Select>
                    </HStack>
                    <HStack>
                        <Text>{`${(currentPage - 1) * pageSize + 1}-${Math.min(currentPage * pageSize, totalInvoices)} de ${totalInvoices}`}</Text>
                        <IconButton
                            icon={<FaChevronLeft />}
                            aria-label="Previous page"
                            isDisabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        />
                        <IconButton
                            icon={<FaChevronRight />}
                            aria-label="Next page"
                            isDisabled={!hasMore}
                            onClick={() => handlePageChange(currentPage + 1)}
                        />
                    </HStack>
                </Flex>
            </Flex>

            <UploadConfirmationModal
                isOpen={isUploadModalOpen}
                onClose={() => setIsUploadModalOpen(false)}
                onConfirm={handleConfirmUpload}
                uploadTarget={uploadTarget}
                selectedInvoicesData={selectedInvoicesData}
                selectedInvoices={selectedInvoices}
            />
        </Box>
    );
}
