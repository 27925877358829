import {
    Box,
    Text,
    VStack,
    SimpleGrid,
    Input,
    InputGroup,
    InputRightAddon,
    IconButton,
    Heading,
    Flex,
    Collapse,
    Select,
    HStack,
    Popover,
    PopoverContent,
    PopoverBody,
    PopoverTrigger,
    Button,
    Badge,
    Tooltip,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon, InfoIcon } from "@chakra-ui/icons";
import { Select as ChakraSelect } from "chakra-react-select";
import React, { useState, useCallback } from "react";
import { Invoice } from "../../types/invoice.types";
import { conversionExamples, measurementExamples } from "./utils/conversionsExamples";
import { getConvertedQuantity, unitConversionFactors } from "./utils/conversionFactors";

interface ProductsTableProps {
    invoice: Invoice;
    itemsOptions: any[];
    optionsConversionMeasurements: any[];
    optionsTaxes: any[];
    inventoryItems: any[];
    handleItemChange: (selectedOption: any, index: number) => void;
    handleTaxChange: (selectedOption: any, index: number, taxIndex: number) => void;
    handleTaxAmountChange: (value: any, index: number, taxName: string) => void;
    setInvoice: (invoice: Invoice) => void;
}

export const ProductsTable: React.FC<ProductsTableProps> = ({
    invoice,
    itemsOptions,
    optionsConversionMeasurements,
    optionsTaxes,
    inventoryItems,
    handleItemChange,
    handleTaxChange,
    handleTaxAmountChange,
    setInvoice,
}) => {
    const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});
    const [currentExampleIndex, setCurrentExampleIndex] = useState<{ [key: string]: number }>({});
    console.log(invoice)

    const handleExampleNavigation = (type: string, direction: 'next' | 'back') => {
        setCurrentExampleIndex(prev => {
            const currentIndex = prev[type] || 0;
            const examples = type === 'conversion' ? conversionExamples : measurementExamples;

            if (direction === 'next') {
                return { ...prev, [type]: (currentIndex + 1) % examples.length };
            } else {
                return { ...prev, [type]: currentIndex === 0 ? examples.length - 1 : currentIndex - 1 };
            }
        });
    };

    const toggleItem = (index: number) => {
        setOpenItems(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const formatCurrency = useCallback((value: number) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value);
    }, []);

    return (
        <Box
            bg="white"
            p={4}
            borderRadius="lg"
            boxShadow="sm"
            border="1px"
            borderColor="gray.200"
        >
            <Heading size="sm" mb={4}>Productos</Heading>
            <VStack align="stretch" spacing={4}>
                {invoice?.items.map((item, index) => {
                    const taxes = Object.keys(item.taxes);
                    const isItemOpen = openItems[index];

                    return (
                        <Box key={index} p={4} borderWidth="1px" borderRadius="md">
                            <Flex justify="space-between" align="center" mb={isItemOpen ? 4 : 0}>
                                <Text fontSize="larger" fontWeight="bold">
                                    {item.description}
                                </Text>
                                <IconButton
                                    aria-label="Toggle item details"
                                    icon={isItemOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                    onClick={() => toggleItem(index)}
                                    variant="ghost"
                                    size="sm"
                                />
                            </Flex>
                            <Collapse in={isItemOpen} animateOpacity>
                                <SimpleGrid columns={2} spacing={4} mt={4}>
                                    <Text fontWeight="bold">Descripción:</Text>
                                    <Box>
                                        <ChakraSelect
                                            defaultValue={{
                                                label: item.inventory_item_name,
                                                value: item.inventory_item_name
                                            }}
                                            options={itemsOptions}
                                            onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                                        />
                                    </Box>

                                    <Text fontWeight="bold">Cantidad en factura:</Text>
                                    <Input
                                        value={item.quantity}
                                        type="number"
                                        disabled={true}
                                        onChange={(e) => {
                                            const newItems = [...invoice.items];
                                            newItems[index] = {
                                                ...item,
                                                quantity: Number(e.target.value)
                                            };
                                            setInvoice({ ...invoice, items: newItems });
                                        }}
                                    />

                                    <Text fontWeight="bold">Precio de item en factura:</Text>
                                    <InputGroup>
                                        <Input
                                            value={formatCurrency(item.price_per_unit)}
                                            type="text"
                                            disabled={true}
                                            onChange={(e) => {
                                                const newItems = [...invoice.items];
                                                const numericValue = Number(e.target.value.replace(/[^0-9.-]+/g, ''));
                                                newItems[index] = {
                                                    ...item,
                                                    price_per_unit: numericValue
                                                };
                                                setInvoice({ ...invoice, items: newItems });
                                            }}
                                        />
                                        <InputRightAddon>COP</InputRightAddon>
                                    </InputGroup>

                                    <Text fontWeight="bold">Precio total:</Text>
                                    <InputGroup>
                                        <Input
                                            value={formatCurrency(item.price)}
                                            type="text"
                                            disabled={true}
                                            onChange={(e) => {
                                                const newItems = [...invoice.items];
                                                const numericValue = Number(e.target.value.replace(/[^0-9.-]+/g, ''));
                                                newItems[index] = {
                                                    ...item,
                                                    price: numericValue
                                                };
                                                setInvoice({ ...invoice, items: newItems });
                                            }}
                                        />
                                        <InputRightAddon>COP</InputRightAddon>
                                    </InputGroup>

                                    {item.inventory_item_name && (
                                        <>
                                            <HStack>
                                                <Text fontWeight="bold">Unidades por empaque:</Text>
                                                <Popover>
                                                    <PopoverTrigger>
                                                        <Badge cursor="pointer">Ver ejemplos</Badge>
                                                    </PopoverTrigger>
                                                    <PopoverContent p={4}>
                                                        <PopoverBody>
                                                            <VStack align="left" spacing={2}>
                                                                <Text><b>Producto:</b> <i>{conversionExamples[currentExampleIndex['conversion'] || 0].product}</i></Text>
                                                                <Text><b>Unidades por empaque:</b> <i>{conversionExamples[currentExampleIndex['conversion'] || 0].unitsPerPack}</i></Text>
                                                                <HStack justify="space-between" mt={2}>
                                                                    <Button size="sm" onClick={() => handleExampleNavigation('conversion', 'back')}>Anterior</Button>
                                                                    <Button size="sm" onClick={() => handleExampleNavigation('conversion', 'next')}>Siguiente</Button>
                                                                </HStack>
                                                            </VStack>
                                                        </PopoverBody>
                                                    </PopoverContent>
                                                </Popover>
                                            </HStack>

                                            <Input
                                                value={item.conversion?.units || 1}
                                                type="number"
                                                onChange={(e) => {
                                                    const newItems = [...invoice.items];
                                                    const conversion = {
                                                        units: Number(e.target.value),
                                                        measurement: item.conversion?.measurement || 'un',
                                                        units_measurement: item.conversion?.units_measurement || 0
                                                    }
                                                    const converted_quantity = getConvertedQuantity(item.quantity, conversion, item.unit || 'un')
                                                    const converted_price = getConvertedQuantity(item.price_per_unit, conversion, item.unit || 'un', true)
                                                    newItems[index] = {
                                                        ...item,
                                                        conversion: conversion,
                                                        converted_quantity: converted_quantity,
                                                        converted_price: converted_price,
                                                    };
                                                    setInvoice({ ...invoice, items: newItems });
                                                }}
                                            />
                                        </>
                                    )}

                                    {item.unit && !unitConversionFactors.includes(item.unit) && (
                                        <>
                                            <HStack> <Text fontWeight="bold">Medidas de cada unidad:</Text> <Popover>
                                                <PopoverTrigger>
                                                    <Badge cursor="pointer">Ver ejemplos</Badge>
                                        </PopoverTrigger>
                                        <PopoverContent p={4}>
                                            <PopoverBody>
                                                <VStack align="left" spacing={2}>
                                                    <Text>Producto: <i>{measurementExamples[currentExampleIndex['measurement'] || 0].product}</i></Text>
                                                    <Text>Medidas de cada unidad: <i>{measurementExamples[currentExampleIndex['measurement'] || 0].measurement} | {measurementExamples[currentExampleIndex['measurement'] || 0].unit}</i></Text>
                                                    <HStack justify="space-between" mt={2}>
                                                        <Button size="sm" onClick={() => handleExampleNavigation('measurement', 'back')}>Anterior</Button>
                                                        <Button size="sm" onClick={() => handleExampleNavigation('measurement', 'next')}>Siguiente</Button>
                                                    </HStack>
                                                </VStack>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover> </HStack>
                                    <HStack>
                                        <Input
                                            value={item.conversion?.units_measurement || 0}
                                            type="number"
                                            onChange={(e) => {
                                                const newItems = [...invoice.items];
                                                const conversion = {
                                                    units: item.conversion?.units || 1,
                                                    measurement: item.conversion?.measurement || 'un',
                                                    units_measurement: Number(e.target.value)
                                                }
                                                const converted_quantity = getConvertedQuantity(item.quantity, conversion, item.unit || 'un')
                                                const converted_price = getConvertedQuantity(item.price_per_unit, conversion, item.unit || 'un', true)
                                                newItems[index] = {
                                                    ...item,
                                                    converted_quantity: converted_quantity,
                                                    converted_price: converted_price,
                                                    conversion: conversion
                                                };
                                                setInvoice({ ...invoice, items: newItems });
                                            }}
                                        />
                                        <Select
                                            value={item.conversion?.measurement || 'un'}
                                            onChange={(e) => {
                                                const newItems = [...invoice.items];
                                                const conversion = {
                                                    units: item.conversion?.units || 1,
                                                    measurement: e.target.value,
                                                    units_measurement: item.conversion?.units_measurement || 0
                                                }
                                                const converted_quantity = getConvertedQuantity(item.quantity, conversion, item.unit || 'un')
                                                const converted_price = getConvertedQuantity(item.price_per_unit, conversion, item.unit || 'un', true)
                                                newItems[index] = {
                                                    ...item,
                                                    converted_quantity: converted_quantity,
                                                    converted_price: converted_price,
                                                    conversion: conversion
                                                };
                                                setInvoice({ ...invoice, items: newItems });
                                            }}
                                        >
                                            {optionsConversionMeasurements.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                        </HStack>
                                    </>
                                    )}
                                    {item.inventory_item_name && (
                                        <>
                                            <HStack> <Text fontWeight="bold">Cantidad subida a POS:</Text> <Tooltip label="La unidad mostrada es la que requiere el item del POS" hasArrow><InfoIcon color="gray.500" /></Tooltip></HStack>
                                            <InputGroup>
                                                <Input
                                                    value={item.converted_quantity || (item.quantity && item.conversion && item.unit ? getConvertedQuantity(item.quantity, item.conversion, item.unit, false) : item.quantity)}
                                                    type="number"
                                                    disabled={true}
                                                />
                                        <InputRightAddon>{inventoryItems.find(invItem => invItem.item_name === item.inventory_item_name)?.item_unit || ''}</InputRightAddon>
                                            </InputGroup>

                                            <HStack> <Text fontWeight="bold">Precio unitario subido a POS:</Text> <Tooltip label="El precio mostrado es el que se sube al POS" hasArrow><InfoIcon color="gray.500" /></Tooltip></HStack>
                                            <InputGroup>
                                                <Input
                                                    value={formatCurrency(item.converted_price || (item.price_per_unit && item.conversion && item.unit ? 
                                                        getConvertedQuantity(item.price_per_unit, item.conversion, item.unit, true) : 
                                                        item.price_per_unit))}
                                                    type="text"
                                                    disabled={true}
                                                />
                                                <InputRightAddon>COP x {inventoryItems.find(invItem => invItem.item_name === item.inventory_item_name)?.item_unit}</InputRightAddon>
                                            </InputGroup>

                                        </>
                                    )}
                                    {taxes.length > 0 && item.taxes[taxes[0]] && (
                                        <>
                                            <Text fontWeight="bold">Impuesto 1:</Text>
                                            <ChakraSelect
                                                defaultValue={{ label: taxes[0], value: taxes[0] }}
                                                options={optionsTaxes}
                                                isDisabled={true}
                                                onChange={(selectedOption) => handleTaxChange(selectedOption, index, 0)}
                                            />
                                            <Text fontWeight="bold">Valor impuesto 1:</Text>
                                            <InputGroup>
                                                <Input
                                                    value={formatCurrency(item.taxes[taxes[0]]?.tax_amount)}
                                                    type="text"
                                                    disabled={true}
                                                    onChange={(e) => handleTaxAmountChange(e.target.value, index, taxes[0])}
                                                />
                                                <InputRightAddon>COP</InputRightAddon>
                                            </InputGroup>
                                        </>
                                    )}

                                    {taxes.length > 1 && item.taxes[taxes[1]] && (
                                        <>
                                            <Text fontWeight="bold">Impuesto 2:</Text>
                                            <ChakraSelect
                                                defaultValue={{ label: taxes[1], value: taxes[1] }}
                                                options={optionsTaxes}
                                                isDisabled={true}
                                                onChange={(selectedOption) => handleTaxChange(selectedOption, index, 1)}
                                            />
                                            <Text fontWeight="bold">Valor impuesto 2:</Text>
                                            <InputGroup>
                                                <Input
                                                    value={formatCurrency(item.taxes[taxes[1]]?.tax_amount)}
                                                    type="text"
                                                    disabled={true}
                                                    onChange={(e) => handleTaxAmountChange(e.target.value, index, taxes[1])}
                                                />
                                                <InputRightAddon>COP</InputRightAddon>
                                            </InputGroup>
                                        </>
                                    )}
                                </SimpleGrid>
                            </Collapse>
                        </Box>
                    );
                })}
            </VStack>
        </Box>
    );
}; 