import {
    Box,
    Button,
    Flex,
    Icon,
    List,
    ListItem,
    Text,
    VStack,
} from "@chakra-ui/react"
import { NavLink, useLocation } from "react-router-dom"
import { FaFileInvoice, FaMoneyBill } from "react-icons/fa";
import { ExternalLinkIcon } from "@chakra-ui/icons";

interface NavButtonProps {
    to: string;
    icon: any;
    children: React.ReactNode;
}

const NavButton: React.FC<NavButtonProps> = ({ to, icon, children }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <NavLink to={to} style={{ width: '100%' }}>
            <Button
                width="100%"
                height="40px"
                variant="ghost"
                justifyContent="flex-start"
                bg={isActive ? 'whiteAlpha.900' : 'transparent'}
                color={isActive ? 'blue.500' : 'whiteAlpha.900'}
                _hover={{
                    bg: isActive ? 'whiteAlpha.900' : 'whiteAlpha.100',
                    transform: 'translateX(2px)',
                }}
                _active={{
                    bg: isActive ? 'whiteAlpha.900' : 'whiteAlpha.200',
                }}
                transition="all 0.15s ease"
                borderRadius="md"
                px={3}
                fontSize="sm"
            >
                <Flex align="center" width="100%">
                    <Icon
                        as={icon}
                        boxSize="16px"
                        mr={2.5}
                    />
                    <Text fontWeight={isActive ? "500" : "400"}>
                        {children}
                    </Text>
                </Flex>
            </Button>
        </NavLink>
    );
};

export default function Sidebar() {
    const navItems = [
        { to: "/", icon: FaFileInvoice, label: "Facturas" },
        { to: "/purchase_sales", icon: ExternalLinkIcon, label: "Compras y Ventas" },
        { to: "/dian", icon: FaMoneyBill, label: "DIAN" },
    ];

    return (
        <Box py={3}>
            <VStack spacing={6} align="stretch">
                <Box px={3}>
                    <Text
                        color="white"
                        fontSize="md"
                        fontWeight="500"
                        letterSpacing="wide"
                        opacity={0.95}
                    >
                        Dashboard
                    </Text>
                </Box>

                <List spacing={1}>
                    {navItems.map((item, index) => (
                        <ListItem key={index} px={3}>
                            <NavButton
                                to={item.to}
                                icon={item.icon}
                            >
                                {item.label}
                            </NavButton>
                        </ListItem>
                    ))}
                </List>
            </VStack>
        </Box>
    )
}
