import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Icon,
    Box,
    Collapse,
    HStack,
} from "@chakra-ui/react";
import { Invoice, InvoiceValidations, TaxxaValidation } from "../../types/invoice.types";
import { FaChevronDown, FaChevronRight, FaCheck, FaExclamationTriangle, FaRegClock } from "react-icons/fa";

interface DianAcknowledgmentModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    selectedInvoices: Invoice[];
}

const InvoiceRow: React.FC<{ invoice: Invoice }> = ({ invoice }) => {
    const [isOpen, setIsOpen] = useState(false);

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
        }).format(amount);
    };

    const getTaxxaStatus = (validations?: InvoiceValidations) => {
        if (!validations?.taxxa) return 'pending';
        
        const taxxa = validations.taxxa;
        const processes = ['acuse_recibo', 'recibo_de_bienes', 'aceptacion_expresa'];
        const completedProcesses = processes.filter(p => taxxa[p as keyof TaxxaValidation]);
        
        if (completedProcesses.length === 0) return 'pending';
        if (completedProcesses.length === processes.length) return 'completed';
        return 'partial';
    };

    return (
        <>
            <Tr 
                cursor="pointer" 
                onClick={() => setIsOpen(!isOpen)}
                _hover={{ bg: "gray.50" }}
            >
                <Td width="40px">
                    <Icon 
                        as={isOpen ? FaChevronDown : FaChevronRight} 
                        color="gray.500"
                    />
                </Td>
                <Td>{invoice.invoice_id}</Td>
                <Td>{invoice.sender_party_name}</Td>
                <Td>{new Date(invoice.issue_date).toLocaleDateString()}</Td>
                <Td>
                    <HStack spacing={2}>
                        <Icon
                            as={
                                getTaxxaStatus(invoice.validations) === 'completed' ? FaCheck :
                                getTaxxaStatus(invoice.validations) === 'partial' ? FaExclamationTriangle :
                                FaRegClock
                            }
                            color={
                                getTaxxaStatus(invoice.validations) === 'completed' ? "green.500" :
                                getTaxxaStatus(invoice.validations) === 'partial' ? "orange.500" :
                                "gray.500"
                            }
                        />
                        <Text fontSize="sm" color={
                            getTaxxaStatus(invoice.validations) === 'completed' ? "green.500" :
                            getTaxxaStatus(invoice.validations) === 'partial' ? "orange.500" :
                            "gray.500"
                        }>
                            {getTaxxaStatus(invoice.validations) === 'completed' ? "Completado" :
                             getTaxxaStatus(invoice.validations) === 'partial' ? "Parcial" :
                             "Pendiente"}
                        </Text>
                    </HStack>
                </Td>
            </Tr>
            <Tr>
                <Td colSpan={4} p={0}>
                    <Collapse in={isOpen} animateOpacity>
                        <Box 
                            p={4} 
                            bg="gray.50" 
                            borderBottom="1px" 
                            borderColor="gray.200"
                        >
                            <Table size="sm" variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Producto</Th>
                                        <Th isNumeric>Cantidad</Th>
                                        <Th isNumeric>Precio unitario</Th>
                                        <Th isNumeric>Total</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {invoice.items.map((item, index) => (
                                        <Tr key={index}>
                                            <Td>{item.description}</Td>
                                            <Td isNumeric>{item.quantity} {item.unit}</Td>
                                            <Td isNumeric>{formatCurrency(item.price_per_unit)}</Td>
                                            <Td isNumeric>{formatCurrency(item.quantity * item.price_per_unit)}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                    </Collapse>
                </Td>
            </Tr>
        </>
    );
};

export const DianAcknowledgmentModal: React.FC<DianAcknowledgmentModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    selectedInvoices,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent maxWidth="80vw">
                <ModalHeader>Confirmar Acuse de Recibo DIAN</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb={4}>
                        ¿Está seguro que desea generar el acuse de recibo para las siguientes facturas?
                    </Text>
                    
                    <Box 
                        p={4} 
                        bg="gray.50" 
                        borderRadius="md" 
                        mb={4}
                    >
                        <Text fontWeight="medium" mb={2}>Resumen:</Text>
                        <HStack spacing={4}>
                            <Text>Total facturas: {selectedInvoices.length}</Text>
                            <Text>
                                Pendientes: {
                                    selectedInvoices.filter(inv => 
                                        !inv.validations?.taxxa || 
                                        Object.keys(inv.validations.taxxa).length === 0
                                    ).length
                                }
                            </Text>
                            <Text>
                                Parciales: {
                                    selectedInvoices.filter(inv => 
                                        inv.validations?.taxxa && 
                                        Object.keys(inv.validations.taxxa).length > 0 &&
                                        Object.keys(inv.validations.taxxa).length < 3
                                    ).length
                                }
                            </Text>
                            <Text>
                                Completadas: {
                                    selectedInvoices.filter(inv => 
                                        inv.validations?.taxxa && 
                                        Object.keys(inv.validations.taxxa).length === 3
                                    ).length
                                }
                            </Text>
                        </HStack>
                    </Box>

                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr>
                                <Th width="40px"></Th>
                                <Th>ID Factura</Th>
                                <Th>Proveedor</Th>
                                <Th>Fecha</Th>
                                <Th>Estado</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {selectedInvoices.map((invoice) => (
                                <InvoiceRow 
                                    key={invoice.id} 
                                    invoice={invoice}
                                />
                            ))}
                        </Tbody>
                    </Table>
                </ModalBody>

                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button colorScheme="blue" onClick={onConfirm}>
                        Confirmar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}; 