type ConversionFactors = {
    [key: string]: {
        [key: string]: number
    }
}

// Possible units of input
//        { label: "un", value: "un" },
// { label: "kg", value: "kg" },
// { label: "lb", value: "lb" },
// { label: "gr", value: "gr" },
// { label: "mg", value: "mg" },
// { label: "ml", value: "ml" },
// { label: "l", value: "l" },
// { label: "oz", value: "oz" },

// Possible units of output
// ['UND', 'un', 'gr', 'kg', 'botella 750 c.c', 'lt', 'ml', 'BOT 700', 'BOT 750', 'porción', 'UND X 750', 'BOT 375', 'botella 500 c.c', 'BT 700ML', 'botella 1000 c.c', 'botella 375 c.c']

const amountConversionFactors: ConversionFactors = {
    ml: {
        l: 0.001,
        lt: 0.001,
        kg: 0.001,
        gr: 1,
        mg: 1000,
        oz: 0.033814,
        un: 1,
        ml: 1

    },
    l: {
        ml: 1000,
        lt: 1,
        kg: 1,
        gr: 1000,
        mg: 1000000,
        oz: 33.814,
        un: 1,
        l: 1
    },
    
    kg: {
        ml: 1000,
        l: 1,
        lt: 1,
        gr: 1000,
        mg: 1000000,
        lb: 2.20462,
        oz: 35.274,
        un: 1,
        kg: 1
    },
    gr: {
        ml: 0.001,
        l: 0.001,
        lt: 0.001,
        kg: 0.001,
        mg: 1000,
        lb: 0.00220462,
        oz: 0.035274,
        un: 0.001,
        gr: 1
    },
    mg: {
        ml: 0.000001,
        l: 0.000001,
        lt: 0.000001,
        kg: 0.000001,
        gr: 0.001,
        lb: 0.00000220462,
        oz: 0.000035274,
        un: 0.000001,
        mg: 1
    },
    lb: {
        kg: 0.453592,
        gr: 453.592,
        mg: 453592,
        oz: 16,
        un: 1,
        lb: 1
    },
    oz: {
        ml: 29.5735,
        l: 0.0295735,
        lt: 0.0295735,
        kg: 0.0283495,
        gr: 28.3495,
        mg: 28349.5,
        lb: 0.0625,
        un: 1,
        oz: 1
    }
}

const priceConversionFactors: ConversionFactors = {
    ml: {
        l: 1000,
        lt: 1000,
        kg: 1000,
        gr: 1,
        mg: 0.001,
        oz: 29.5735,
        un: 1,
        lb: 1,
        ml: 1
    },
    l: {
        ml: 0.001,
        lt: 1,
        kg: 1,
        gr: 0.001,
        mg: 0.000001,
        oz: 0.0295735,
        un: 1,
        l: 1
    },
    kg: {
        ml: 0.001,
        l: 1,
        lt: 1,
        gr: 0.001,
        mg: 0.000001,
        lb: 0.453592,
        oz: 0.0283495,
        un: 1,
        kg: 1
    },
    gr: {
        ml: 1000,
        l: 1000,
        lt: 1000,
        kg: 1000,
        mg: 0.001,
        lb: 453.592,
        oz: 28.3495,
        un: 1000,
        gr: 1
    },
    mg: {
        ml: 1000000,
        l: 1000000,
        lt: 1000000,
        kg: 1000000,
        gr: 1000,
        lb: 453592,
        oz: 28349.5,
        un: 1000000,
        mg: 1
    },
    lb: {
        kg: 2.20462,
        gr: 0.00220462,
        mg: 0.00000220462,
        oz: 0.0625,
        un: 1,
        lb: 1
    },
    oz: {
        ml: 0.033814,
        l: 33.814,
        lt: 33.814,
        kg: 35.274,
        gr: 0.035274,
        mg: 0.000035274,
        lb: 16,
        un: 1,
        oz: 1
    }
}

export const unitConversionFactors = ["UND", "un", "botella 750 c.c", "BOT 700", "BOT 750", "BOT 375", "botella 500 c.c", "BT 700ML", "botella 1000 c.c", "botella 375 c.c"]


export const getConvertedQuantity = (quantity: number, conversion: any, unit: string, priceConversion: boolean = false) => {
    const conversionFactors = priceConversion ? priceConversionFactors : amountConversionFactors
    if (conversion.units > 0) {
        if (priceConversion) {
            quantity = quantity / conversion.units
        } else {
            quantity = quantity * conversion.units
        }
    }
    if (conversion.units_measurement > 0 && !unitConversionFactors.includes(unit)) {
        if (priceConversion) {
            quantity = quantity / conversion.units_measurement
        } else {
            quantity = quantity * conversion.units_measurement
        }
    }

    if (conversionFactors[conversion.measurement] && !unitConversionFactors.includes(unit)) {
        quantity = quantity * conversionFactors[conversion.measurement][unit]
    }

    return quantity
}
