import { 
    VStack, 
    Heading, 
    Input, 
    Button, 
    Box, 
    Text, 
    useToast, 
    InputGroup, 
    InputLeftElement,
    Icon,
    FormControl,
    FormLabel,
    Container,
    Image
} from "@chakra-ui/react";
import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../config/firebaseConfig";
import { MdEmail, MdLock } from "react-icons/md";

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    const validateInputs = () => {
        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            toast({
                title: "Error de validación",
                description: "Por favor ingrese un correo válido",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return false;
        }
        if (!password || password.length < 6) {
            toast({
                title: "Error de validación",
                description: "La contraseña debe tener al menos 6 caracteres",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return false;
        }
        return true;
    };

    const handleLogin = async () => {
        if (!validateInputs()) return;

        setIsLoading(true);
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate("/");
        } catch (error: any) {
            setError('Correo o contraseña incorrectos.');
            toast({
                title: "Error de autenticación",
                description: "Credenciales inválidas. Por favor intente nuevamente.",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <Box 
            minH="100vh" 
            bg="gray.50" 
            py={20}
            px={4}
        >
            <Container maxW="400px">
                <VStack spacing={8} align="center">
                    {/* Logo or company name could go here */}
                    {/* <Image 
                        src="/logo.png" 
                        alt="Logo" 
                        maxW="200px"
                        mb={4}
                    /> */}
                    
                    <Box 
                        w="full"
                        bg="white" 
                        p={8} 
                        borderRadius="lg"
                        boxShadow="sm"
                        border="1px solid"
                        borderColor="gray.200"
                    >
                        <VStack spacing={6}>
                            <Heading 
                                as="h1" 
                                size="lg" 
                                color="gray.700"
                                fontWeight="600"
                            >
                                Iniciar Sesión
                            </Heading>

                            {error && (
                                <Text 
                                    color="red.500" 
                                    fontSize="sm" 
                                    textAlign="center"
                                >
                                    {error}
                                </Text>
                            )}

                            <FormControl>
                                <FormLabel fontSize="sm" color="gray.600">
                                    Correo electrónico
                                </FormLabel>
                                <InputGroup>
                                    <InputLeftElement>
                                        <Icon as={MdEmail} color="gray.500" />
                                    </InputLeftElement>
                                    <Input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        placeholder="correo@ejemplo.com"
                                        bg="white"
                                        borderColor="gray.300"
                                        _hover={{ borderColor: "gray.400" }}
                                        _focus={{ 
                                            borderColor: "blue.400",
                                            boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)"
                                        }}
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <FormLabel fontSize="sm" color="gray.600">
                                    Contraseña
                                </FormLabel>
                                <InputGroup>
                                    <InputLeftElement>
                                        <Icon as={MdLock} color="gray.500" />
                                    </InputLeftElement>
                                    <Input
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        placeholder="••••••••"
                                        bg="white"
                                        borderColor="gray.300"
                                        _hover={{ borderColor: "gray.400" }}
                                        _focus={{ 
                                            borderColor: "blue.400",
                                            boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)"
                                        }}
                                    />
                                </InputGroup>
                            </FormControl>

                            <Button
                                colorScheme="blue"
                                width="full"
                                onClick={handleLogin}
                                isLoading={isLoading}
                                loadingText="Iniciando sesión..."
                                height="44px"
                                fontSize="md"
                                _hover={{ 
                                    bg: "blue.600",
                                    transform: "translateY(-1px)",
                                    boxShadow: "sm"
                                }}
                                _active={{ 
                                    transform: "translateY(0)",
                                    boxShadow: "none"
                                }}
                                transition="all 0.2s"
                            >
                                Iniciar Sesión
                            </Button>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}