import {
    Box,
    Button,
    Flex,
    Heading,
    Text,
    VStack,
    useToast,
    Grid,
    GridItem,
    HStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Invoice, PurchaseOrderValidation } from "../types/invoice.types";
import { updateInvoice } from "../services/invoices.services";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { fetchInventoryItems } from "../services/invoices.services";
import { ProductsTable } from "../components/invoicing/ProductsTable";
import { PurchaseOrderSection } from "../components/invoicing/PurchaseOrderSection";
import { UploadControls } from "../components/invoicing/UploadControls";
import { getConvertedQuantity } from "../components/invoicing/utils/conversionFactors";

export default function InvoiceDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const [invoice, setInvoice] = useState<Invoice | null>(null);
    const [loading, setLoading] = useState(true);
    const [inventoryItems, setInventoryItems] = useState<any[]>([]);
    const [itemsOptions, setItemsOptions] = useState<any[]>([]);

    React.useEffect(() => {
        // Get invoice from localStorage (passed from the main screen)
        const invoiceData = localStorage.getItem('selectedInvoice');
        if (invoiceData) {
            const parsedInvoice = JSON.parse(invoiceData);            
            setInvoice(parsedInvoice);
        }
        setLoading(false);
    }, [id]);

    React.useEffect(() => {
        fetchInventoryItems().then((data) => {
            if (data) {
                setInventoryItems(data.inventory_items);
                const items = data.inventory_items.map((item: any) => ({
                    label: item.item_name,
                    value: item.item_name
                }));
                setItemsOptions(items);
            }
        });
    }, []);

    const optionsConversionMeasurements = [
        { label: "un", value: "un" },
        { label: "kg", value: "kg" },
        { label: "lb", value: "lb" },
        { label: "gr", value: "gr" },
        { label: "mg", value: "mg" },
        { label: "ml", value: "ml" },
        { label: "l", value: "l" },
        { label: "oz", value: "oz" },
    ];

    const handleItemChange = (selectedOption: any, index: number) => {
        const item = inventoryItems.find((item: any) => item.item_name === selectedOption.value);
        if (item && invoice) {
            const updatedItems = [...invoice.items];
            updatedItems[index] = {
                ...updatedItems[index],
                unit: item.item_unit,
                inventory_item_name: item.item_name,
                inventory_id: item.id,
                item_role_id: item.item_role_id,
                item_role_name: item.item_role_name
            };
            setInvoice({ ...invoice, items: updatedItems });
        }
    };

    const handleTaxChange = (selectedOption: any, index: number, taxIndex: number) => {
        if (!invoice) return;
        const updatedItems = [...invoice.items];
        const taxName = selectedOption.value;
        const updatedTaxes = { ...updatedItems[index].taxes };
        const oldTaxName = Object.keys(updatedTaxes)[taxIndex];
        const oldTaxAmount = updatedTaxes[oldTaxName]?.tax_amount || 0;
        delete updatedTaxes[oldTaxName];
        updatedTaxes[taxName] = { tax_amount: oldTaxAmount };
        updatedItems[index] = {
            ...updatedItems[index],
            taxes: updatedTaxes,
        };
        setInvoice({ ...invoice, items: updatedItems });
    };

    const handleTaxAmountChange = (value: any, index: number, taxName: string) => {
        if (!invoice) return;
        const updatedItems = [...invoice.items];
        const updatedTaxes = { ...updatedItems[index].taxes };
        updatedTaxes[taxName] = { tax_amount: Number(value) };
        updatedItems[index] = {
            ...updatedItems[index],
            taxes: updatedTaxes,
        };
        setInvoice({ ...invoice, items: updatedItems });
    };

    const optionsTaxes = [
        { label: "IVA 0%", value: "IVA 0%" },
        { label: "IVA 16%", value: "IVA 16%" },
        { label: "IVA 5%", value: "IVA 5%" },
        { label: "INC (COMPRAS)", value: "INC (COMPRAS)" },
        { label: "IVA (VAR)%", value: "IVA (VAR)%" },
        { label: "IVA 19%", value: "IVA 19%" },
        { label: "Bolsas", value: "Bolsas" },
        { label: "IC Porcentual", value: "IC Porcentual" },
        { label: "INC 9%", value: "INC 9%" },
        { label: "IBUA < 6gr/100 ml", value: "IBUA < 6gr/100 ml" },
        { label: "IBUA >= 6gr < 10gr / 100 ml", value: "IBUA >= 6gr < 10gr / 100 ml" },
        { label: "IBUA >= 10gr / 100 ml", value: "IBUA >= 10gr / 100 ml" },
        { label: "ICUI", value: "ICUI" },
        { label: "I.G.V 18", value: "I.G.V 18" }
    ];

    const calculateConversions = (invoiceToUpdate: Invoice): Invoice => {
        const updatedInvoice = { ...invoiceToUpdate };
        
        updatedInvoice.items = updatedInvoice.items.map(item => {
            // Only calculate if we have the necessary information AND the values aren't already set
            if (item.inventory_item_name && 
                item.quantity && 
                item.conversion && 
                item.unit && 
                (!item.converted_quantity || !item.converted_price)) {
                
                return {
                    ...item,
                    converted_quantity: item.converted_quantity || getConvertedQuantity(
                        item.quantity,
                        item.conversion,
                        item.unit,
                        false
                    ),
                    converted_price: item.converted_price || getConvertedQuantity(
                        item.price_per_unit,
                        item.conversion,
                        item.unit,
                        true
                    )
                };
            }
            return item;
        });

        return updatedInvoice;
    };

    const handleSaveInvoice = () => {
        if (!invoice) return;
        
        // Calculate any missing conversions
        const updatedInvoice = calculateConversions(invoice);
        
        // Update the local state
        setInvoice(updatedInvoice);
        
        // Send to backend
        updateInvoice({ invoice: updatedInvoice }).then((data) => {
            toast({
                title: "Factura actualizada",
                status: data.status,
                duration: 3000,
                isClosable: true,
            });
        });
    };

    if (loading || !invoice) {
        return <Box p={4}>Loading...</Box>;
    }

    return (
        <Box p={4}>
            <Flex direction="column" gap={6}>
                {/* Header */}
                <Flex
                    justify="space-between"
                    align="center"
                    bg="white"
                    p={4}
                    borderRadius="lg"
                    boxShadow="sm"
                    border="1px"
                    borderColor="gray.200"
                >
                    <Button
                        leftIcon={<ArrowBackIcon />}
                        variant="ghost"
                        onClick={() => navigate('/')}
                    >
                        Volver
                    </Button>
                    <Heading size="md">Factura {invoice.invoice_id}</Heading>
                    <HStack spacing={3}>
                        <UploadControls 
                            selectedInvoices={[invoice.id]}
                            selectedInvoicesData={[invoice]}
                            isDetailView={true}
                            currentInvoice={invoice}
                        />
                        <Button
                            colorScheme="blue"
                            onClick={handleSaveInvoice}
                        >
                            Guardar cambios
                        </Button>
                    </HStack>
                </Flex>

                {/* Content Grid */}
                <Grid templateColumns="2fr 1fr" gap={6}>
                    {/* Left Column - Invoice Details & Products */}
                    <GridItem>
                        <VStack spacing={6} align="stretch">
                            {/* Invoice Details */}
                            <Box
                                bg="white"
                                p={4}
                                borderRadius="lg"
                                boxShadow="sm"
                                border="1px"
                                borderColor="gray.200"
                            >
                                <VStack align="stretch" spacing={4}>
                                    <Heading size="sm">Detalles de la factura</Heading>
                                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                        <Box>
                                            <Text fontWeight="bold">Proveedor</Text>
                                            <Text>{invoice.sender_party_name}</Text>
                                        </Box>
                                        <Box>
                                            <Text fontWeight="bold">NIT</Text>
                                            <Text>{invoice.sender_party}</Text>
                                        </Box>
                                        <Box>
                                            <Text fontWeight="bold">Fecha</Text>
                                            <Text>{new Date(invoice.issue_date).toLocaleDateString()}</Text>
                                        </Box>
                                        <Box>
                                            <Text fontWeight="bold">CUFE</Text>
                                            <Text fontSize="sm" noOfLines={1}>{invoice.invoice_cufe}</Text>
                                        </Box>
                                    </Grid>
                                </VStack>
                            </Box>

                            {/* Products Table */}
                            <ProductsTable
                                invoice={invoice}
                                itemsOptions={itemsOptions}
                                inventoryItems={inventoryItems}
                                optionsConversionMeasurements={optionsConversionMeasurements}
                                optionsTaxes={optionsTaxes}
                                handleItemChange={handleItemChange}
                                handleTaxChange={handleTaxChange}
                                handleTaxAmountChange={handleTaxAmountChange}
                                setInvoice={setInvoice}
                            />

                            {/* Purchase Order Section */}
                            {invoice.validations?.purchase_order && typeof invoice.validations.purchase_order === 'object' && 
                                <PurchaseOrderSection validation={invoice.validations.purchase_order as PurchaseOrderValidation} />
                            }
                        </VStack>
                    </GridItem>

                    {/* Right Column - PDF Viewer */}
                    <GridItem>
                        <Box
                            bg="white"
                            borderRadius="lg"
                            boxShadow="sm"
                            border="1px"
                            borderColor="gray.200"
                            height="calc(100vh - 150px)"
                            position="sticky"
                            top="20px"
                        >
                            <iframe
                                src={invoice.pdf_url}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    border: 'none',
                                    borderRadius: '8px'
                                }}
                                title="PDF Viewer"
                            />
                        </Box>
                    </GridItem>
                </Grid>
            </Flex>
        </Box>
    );
} 