import { useEffect, useState, useRef } from "react";
import { 
    Flex, 
    Heading, 
    Button, 
    Icon,
    Text,
    HStack,
    Divider,
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Avatar
} from "@chakra-ui/react";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { fetchBizName } from "../../services/layout.services";
import { FiLogOut, FiChevronDown } from "react-icons/fi";

export default function Navbar() {
    const navigate = useNavigate();
    const [bizName, setBizName] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const mounted = useRef(false);
    const [authInitialized, setAuthInitialized] = useState(false);

    const getBizName = async () => {
        setIsLoading(true);
        try {
            const name = await fetchBizName();
            if (mounted.current) {
                setBizName(name);
            }
        } catch (error) {
            console.error("Error fetching heading name: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        mounted.current = true;

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (mounted.current) {
                setAuthInitialized(true);
                if (user) {
                    getBizName();
                }
            }
        });

        return () => {
            mounted.current = false;
            unsubscribe();
        };
    }, []);

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate("/login");
        } catch (error) {
            console.error("Error logging out: ", error);
        }
    };

    return (
        <Box>
            <Flex 
                as="nav" 
                align="center" 
                justify="space-between"
                py={3}
                px={1}
            >
                <Heading 
                    size="md" 
                    color="gray.700"
                    fontWeight="500"
                >
                    {bizName || "Dashboard"}
                </Heading>

                <HStack spacing={4}>
                    <Menu>
                        <MenuButton
                            as={Button}
                            variant="ghost"
                            size="sm"
                            rightIcon={<Icon as={FiChevronDown} />}
                            _hover={{ bg: 'gray.100' }}
                            px={2}
                        >
                            <HStack spacing={2}>
                                <Avatar 
                                    size="xs" 
                                    name={bizName} 
                                    bg="blue.500"
                                />
                                <Text 
                                    fontSize="sm" 
                                    color="gray.700"
                                    display={{ base: 'none', md: 'block' }}
                                >
                                    {bizName}
                                </Text>
                            </HStack>
                        </MenuButton>
                        <MenuList 
                            shadow="md" 
                            border="1px solid" 
                            borderColor="gray.200"
                            py={1}
                        >
                            <MenuItem
                                icon={<Icon as={FiLogOut} />}
                                onClick={handleLogout}
                                fontSize="sm"
                                color="gray.700"
                                _hover={{
                                    bg: 'gray.50',
                                }}
                            >
                                Cerrar Sesión
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </HStack>
            </Flex>
            <Divider borderColor="gray.200" />
        </Box>
    );
}