const conversionExamples = [
    {
        product: "6-pack redbull 200ml",
        unitsPerPack: 6
    },
    {
        product: "Agua Cristal P300mlx24 SP",
        unitsPerPack: 24
    },
    {
        product: "Empanadas de pollo x 30",
        unitsPerPack: 30
    }
];

const measurementExamples = [
    {
        product: "6-pack redbull 200ml",
        measurement: "200",
        unit: "ml"
    },
    {
        product: "2018 RON MEDELLIN 5 ANOS 750ML",
        measurement: "750",
        unit: "ml"
    },
    {
        product: "Aceite cocina 1L",
        measurement: "1",
        unit: "l"
    }
];

export { conversionExamples, measurementExamples };