import {Box, Flex} from "@chakra-ui/react";
import NomenclatureItem from "./NomenclatureItem";
import {CheckIcon, CloseIcon, QuestionIcon} from "@chakra-ui/icons";
import React from "react";

const NomenclatureBox = () => {
    // TODO: Make the nomenclature box a way to filter the results
    return (
        <Box height={"150px"} bg={"white"} mb={"5px"} border={"2px"} borderColor={"lightgrey"} borderRadius={"10px"} color={"black"} p={"15px"}>
                    <Flex direction="column" justifyContent="space-between" height="100%">
                        <NomenclatureItem description="La información coincide" icon={CheckIcon}
                                          boxColor={"green"}/>
                        <NomenclatureItem description={"Existen diferencias en la información"}
                                          icon={CloseIcon} boxColor={"red"}/>
                        <NomenclatureItem description="No se encontró" icon={QuestionIcon}
                                          boxColor={"black"}/>
                    </Flex>
        </Box>
    )
}

export default NomenclatureBox;