import config from "../config/env.config";
import { getToken } from "../utils/auth";

interface SiigoUploadResponse {
    results: string
}

export const fetchSiigoUploadSales = async (): Promise<any | undefined> => {
    const fileInput = document.querySelector<HTMLInputElement>(`#siigo_sales`);

    if (!fileInput || !fileInput.files) {
        console.error('File inputs or files are not available');
        return;
    }

    const file = fileInput.files[0];

    if (!file) {
        console.error('Files are not available');
        return;
    }

    const newFile = new File([file], `sales_` + file.name, {type: file.type});

    const formData = new FormData();
    formData.append('file', newFile);
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/siigo_upload`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData
        });
        const data = await response.json() as SiigoUploadResponse;
        return data.results;
    } catch (error) {
        console.error('Failed to fetch invoices:', error);
    }
};

export const fetchSiigoUploadPurchases = async (): Promise<any | undefined> => {
    const intuiposInput = document.querySelector<HTMLInputElement>(`#intuiposFile`);
    const dianInput = document.querySelector<HTMLInputElement>(`#dianFile`);

    if (!intuiposInput || !intuiposInput.files || !dianInput || !dianInput.files) {
        console.error('File inputs or files are not available');
        return;
    }

    const intuiposFile = intuiposInput.files[0];
    const dianFile = dianInput.files[0];

    if (!intuiposFile || !dianFile) {
        console.error('Files are not available');
        return;
    }

    const newIntuiposFile = new File([intuiposFile], `intuipos_` + intuiposFile.name, {type: intuiposFile.type});
    const newDianFile = new File([dianFile], `dian_` + dianFile.name, {type: dianFile.type});

    const formData = new FormData();
    formData.append('file', newIntuiposFile);
    formData.append('file2', newDianFile);
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/siigo_upload`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData
        });
        const data = await response.json() as SiigoUploadResponse;
        return data.results;
    } catch (error) {
        console.error('Failed to fetch invoices:', error);
    }
};